import {ref, computed, watch, nextTick} from "@vue/composition-api";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import router from "@/router";
import {debounce} from "lodash";

export default function useList(config) {
  let id_user = config.id_user;
  let id_omniview_reseller = config.id_omniview_reseller;
  const loading = ref(false);
  const {t} = useI18nUtils();
  const idOmniaReseller = ref(parseInt(router.currentRoute.params.id));

  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };

  const refListTable = ref(null);
  const unitsFilter = ref(null);
  const id_unit = ref(null);
  const sectorFilter = ref(null);
  const centerFilter = ref(null);
  const onlineFilter = ref(null);
  const tenantFilter = ref(null);
  const assignment = ref(null);
  const assignmentThisOmniaReseller = ref(null);
  const configComponet = ref({});
  const toast = useToast();
  const assing = ref(null);
  const assingDevicesComponent = ref(null);
  const tableColumns = [
    {
      label: "omniview_reseller.assign",
      key: "idOmniaReseller",
      sortable: false,
    },
    {label: "omniview_reseller.id_camera_proxy", key: "id", sortable: true},
    {label: "omniview_reseller.camera_id", key: "camera_id", sortable: true},
    {label: "omniview_reseller.name", key: "camera.name", sortable: false},
    {
      label: "date.created_at",
      key: "created_at",
      sortable: false,
      formatter: "formatDateAssigned",
    },
    {
      label: "date.updated_at",
      key: "updated_at",
      sortable: false,
      formatter: "formatDateAssigned",
    },
  ];

  const user = JSON.parse(window.localStorage.userData);

  if (user.is_master_admin) {
    tableColumns.splice(3, 0, {label: "omniview_reseller.type", key: "type", sortable: false},)
  }

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  if (config.filters.sector) store.dispatch("sector/getAll", {id_user});
  if (config.filters.units) store.dispatch("unit/getAll");
  store.dispatch("time_zone/getAll");
  if (config.filters.center) store.dispatch("center/getAll", {id_user});
  if (config.filters.tenant) store.dispatch("tenant/getAll", {id_user});

  const unitsOptions = computed(() => store.getters["unit/getSelectedItems"]);

  const sectorOptions = computed(() => store.getters["sector/getSelectedItems"]);

  const timezoneOptions = computed(() => store.getters["time_zone/getSelectedItems"]);

  const centerOptions = computed(() => store.getters["center/getSelectedItems"]);

  const tenantOptions = computed(() => store.getters["tenant/getSelectedItems"]);

  const AssignmentCheckbox = ref(null);

  const onlineOptions = ref([
    {label: "online", value: 1},
    {label: "offline", value: 0},
  ]);

  const assignmentThisOmniaResellerOptions = ref([
    {label: "Asignado", value: 1},
    {label: "No asignado", value: 0},
  ]);

  //bandera para cuando cambias el filtro de asignados.
  const assignedFilterValue = ref(null);

  const fetchList = (ctx, callback) => {
    if (sortBy.value == "") {
      sortBy.value = "id";
    }
    let result = {};
    let params = {
      q: searchQuery.value,
      max: perPage.value,
      "page[n]": currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      id_unit: unitsFilter.value,
      id_sector: sectorFilter.value,
      id_center: centerFilter.value,
      online: onlineFilter.value,
      tenant: tenantFilter.value,
      assign: assignedFilterValue.value,
      //justIds: assing.value, //this boolean is to retrive just the ids or all the camera resources
    };

    //Aqui ya se le pone el id_omniview_reseller o el id_user
    if (configComponet.value != {}) {
      if (id_omniview_reseller) {
        let omniview_reseller = configComponet.value.id_omniview_reseller;
        result = {...params, omniview_reseller};
      }

      if (id_user) {
        let user = configComponet.value.id_user;
        result = {...params, user};
      }
    } else {
      result = params;
    }
    nextTick(() => {
      if (!assingDevicesComponent.value.cameraProxyIds) return
    })
    loading.value = true;

    store
      .dispatch("camera_proxy/getAll", result)
      .then((response) => {
        if (assignedFilterValue.value == 1) {
          //si esta el filtro de solo asignados
          let camerasProxyArray = response.data;

          const camerasProxyArrayWithAssignProperty = camerasProxyArray.map((obj) => { //como esta el filtro activo, todos estan asignados.
            return {...obj, assing: 1};
          });

          if (camerasProxyArray.length > 0) { //si desasigna todos y esta activo el filtro, el checkbox se pone en false.
            AssignmentCheckbox.value = 1;
          } else {
            AssignmentCheckbox.value = 0;
          }

          callback(camerasProxyArrayWithAssignProperty);

          totalList.value = response.meta.total;
        } else {
          //Si no esta el filtro de solo asignados.
          let arrayCamerasProxyIds = assingDevicesComponent.value.cameraProxyIds; //Saca los ids de todos los dispositivos que estan asignados y los mete a esa variable id
          let objectsArray = response.data; //de ahi del la respuesta del cameras_proxy lo pasa a una variable de arreglo

          const newArray = objectsArray.map((obj) => {
            //crea un nuevo arreglo de la respuesta de cameras proxy
            if (arrayCamerasProxyIds.includes(obj.id)) {
              //busca si la camara esta dentro dee los ids de asignados, si si le agrega la bandera assing en 1
              return {...obj, assing: 1};
            } else {
              return obj;
            }
          });

          let allIdsPresent = objectsArray.every((obj) =>
            arrayCamerasProxyIds.includes(obj.id)
          );
          //Aqui valida que si todos los los id que regreso estan asignados si si le pone 1 y si no 0
          if (allIdsPresent) {
            AssignmentCheckbox.value = 1;
          } else {
            AssignmentCheckbox.value = 0;
          }

          callback(newArray); //regresa el nuevo arreglo
          totalList.value = response.meta.total;
        }
        loading.value = false;
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: trans["messages.error.server_error"],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const refetchDataSector = () => {
    store.dispatch("sector/getAll", {
      id_center: centerFilter.value,
    });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch(
    [
      tenantFilter,
      currentPage,
      onlineFilter,
      centerFilter,
      sectorFilter,
      unitsFilter,
      perPage,
      id_unit,
      assignment,
      assignmentThisOmniaReseller,
      assignedFilterValue,
    ],
    () => {
      refetchData();
    }
  );

  // watch(
  //   [
  //     configComponet,
  //   ],
  //   () => {
  //     refetchData();
  //   }
  // );


  watch(
    assingDevicesComponent,
    (currentValue, previousValue) => {
      if (previousValue !== null && currentValue !== null && previousValue !== currentValue && currentValue.cameraProxyIds) {
        refetchData();
      }
    }
  );


  watch([centerFilter], () => {
    refetchDataSector();
  });

  watch([searchQuery], debounce(() => {
    refetchData()
  }, 2000))

  return {
    assignedFilterValue,
    AssignmentCheckbox,
    assingDevicesComponent,
    assing,
    configComponet,
    assignmentThisOmniaResellerOptions,
    assignmentThisOmniaReseller,
    assignment,
    tenantFilter,
    tenantOptions,
    onlineFilter,
    onlineOptions,
    centerFilter,
    centerOptions,
    sectorFilter,
    sectorOptions,
    id_unit,
    idOmniaReseller,
    timezoneOptions,
    unitsFilter,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    refetchData,
    unitsOptions,
    loading
  };
}
